import 'antd/dist/antd.css';
import { Empty } from "antd";

function App() {
  return <div  className="root">
    <Empty description="Information on Michael coming soon!" />
  </div>;
}

export default App;
